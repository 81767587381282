
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import {
  File, Image, Reference, RichTextElement,
} from 'fsxa-api'
import { TCarousel } from '../../../shared/general/types/TCarousel'
import { ILink } from '../../../shared/general/interfaces/ILink'
import { mapImage } from '../../../shared/general/services/ImageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'
import { sendStageAvailable } from '../../../shared/general/services/JubileeService'

interface ISlideData {
  st_image ?: Image
  st_image_alt_text ?: string
  st_image_decorative ?: boolean
  st_checkbox_1 ?: RichTextElement[]
  st_checkbox_2 ?: RichTextElement[]
  st_checkbox_3 ?: RichTextElement[]
  st_link ?: {
    data ?: {
      lt_link ?: Image | File | Reference | string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
    }
  }
}

interface IPayload {
  st_slides ?: TDataEntry<ISlideData>[]
}

@Component({
  name: 'StCareerStage',
  components: { Carousel: () => import('../../carousel/Carousel.vue') },
})
export default class StCareerStage extends FSXABaseSection<IPayload> {
  mounted () {
    sendStageAvailable()
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get carousel () : TCarousel {
    return {
      slides: normalizeDataEntries(this.payload.st_slides).map((data : ISlideData) => ({
        content: {
          links: this.getLinks(data),
          text: '',
          checkmarks: this.getCheckmarks(data),
        },
        image: mapImage(data.st_image, data.st_image_alt_text, data.st_image_alt_text, data.st_image_decorative),
      })) || [],
      variant: 'career-stage',
    }
  }

  private getLinks (slideData ?: ISlideData) : ILink[] {
    return slideData?.st_link ? [getLinkObject(slideData.st_link, this.getUrlByPageId)] : []
  }

  private getCheckmarks (slideData ?: ISlideData) : RichTextElement[][] {
    return [
      (slideData?.st_checkbox_1 || []),
      (slideData?.st_checkbox_2 || []),
      (slideData?.st_checkbox_3 || []),
    ].filter((array) => array.length)
  }
}
