

import { Component, Vue } from 'nuxt-property-decorator'
import HeaderDekraLogo from './header/HeaderDekraLogo.vue'
import BasePicture from './base/BasePicture.vue'
import BaseButton from './base/BaseButton.vue'
import IImage from '../shared/general/interfaces/IImage'
import { ICampaignCta } from '../shared/general/interfaces/ILandingPage'
import { resolutions } from '../shared/general/services/LandingPageService'

@Component({
  name: 'LandingPageHeader',
  components: {
    HeaderDekraLogo,
    BasePicture,
    BaseButton,
  },
})
export default class LandingPageHeader extends Vue {
  private showCampaignCta = false

  private readonly resolutions : Record<number | 'max', string> = resolutions

  $refs! : {
      ctaButton ?: HTMLElement
      logoContainer ?: HTMLElement
  }

  mounted () {
    this.campaignCtaVisibility()
    window.addEventListener('resize', this.campaignCtaVisibility)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.campaignCtaVisibility)
  }

  private get logo2 () : IImage | null {
    return this.$store.state.LandingPage.logo2
  }

  private get logo3 () : IImage | null {
    return this.$store.state.LandingPage.logo3
  }

  private get campaignCta () : ICampaignCta {
    return this.$store.state.LandingPage.campaignCta
  }

  private campaignCtaVisibility () : void {
    const { logoContainer, ctaButton } = this.$refs

    if (!logoContainer || !ctaButton) return

    const logoRect = logoContainer.getBoundingClientRect()
    const buttonRect = ctaButton.getBoundingClientRect()

    const marginBetween = buttonRect.left - logoRect.right

    this.showCampaignCta = marginBetween >= 32
  }
}
