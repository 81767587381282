
import { Component } from 'nuxt-property-decorator'
import type { Link } from 'fsxa-api'
import { FSXABaseLayout, FSXARichText } from 'fsxa-pattern-library'
import BasePicture from '../../base/BasePicture.vue'
import BaseButton from '../../base/BaseButton.vue'
import DesignLogo from '../../svgs/DesignLogo.vue'
import IImage from '../../../shared/general/interfaces/IImage'
import { mapImage } from '../../../shared/general/services/ImageService'
import LifespanAwareContentWrapper from '../wrappers/LifespanAwareContentWrapper'
import { IPayload } from '../../../shared/general/interfaces/ILandingPage'
import { resolutions } from '../../../shared/general/services/LandingPageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

@Component({
  name: 'PtLandingPage',
  components: {
    BasePicture,
    DesignLogo,
    BaseButton,
    LifespanAwareContentWrapper,
    FSXARichText,
  },
})
export default class PtLandingPage extends FSXABaseLayout<IPayload> {
  private contentTopPadding : number | undefined = 0

  private readonly resolutions : Record<number | 'max', string> = resolutions

  created () {
    this.updateHeaderElements()
    this.checkAnchorLink()
  }

  mounted () {
    this.setContentTopPadding()
    window.addEventListener('resize', this.setContentTopPadding)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.setContentTopPadding)
  }

  private get headline () : string {
    return this.data.pt_headline || ''
  }

  checkAnchorLink () : void {
    if (!this.data.pt_anchor_links?.value?.length) return
    this.$store.commit('AnchorLinks/setAnchorLinks', this.data.pt_anchor_links.value)
  }

  private get coverImage () : IImage {
    return mapImage(this.data.pt_image, this.data.pt_image_alt_text, this.data.pt_image_title_text)
  }

  private get logo2 () : IImage {
    return mapImage(this.data.pt_logo_2, this.data.pt_logo_2_alt_text, this.data.pt_logo_2_alt_text)
  }

  private get logo3 () : IImage {
    return mapImage(this.data.pt_logo_3, this.data.pt_logo_3_alt_text, this.data.pt_logo_3_alt_text)
  }

  private get description () : string {
    return this.data.pt_text || ''
  }

  private get campaignCta () : Link | undefined {
    return this.data.pt_campaign_cta
  }

  private get primaryCta () : Link | undefined {
    return this.data.pt_primary_cta
  }

  private get secondaryCta () : Link | undefined {
    return this.data.pt_secondary_cta
  }

  private ctaLink (cta : Link | undefined) : string {
    return getLinkObject(cta, this.getUrlByPageId).url || ''
  }

  private ctaLabel (cta : Link | undefined) : string {
    return cta?.data?.lt_text || ''
  }

  private setContentTopPadding () : void {
    const headerHeight = document.querySelector('#header')?.getBoundingClientRect().height
    if (headerHeight === undefined) return
    this.contentTopPadding = headerHeight - 16
  }

  private updateHeaderElements () : void {
    this.$store.commit('Page/setPageType', 'LandingPage')
    this.$store.commit('LandingPage/setState', {
      logo2: this.logo2,
      logo3: this.logo3,
      campaignCta: {
        type: this.campaignCta?.type || '',
        label: this.campaignCta?.data?.lt_text || '',
        link: this.ctaLink(this.campaignCta),
      },
    })
    this.$store.commit('SocialSharing/setState', {
      displaySocialShareBar: this.data.pt_display_social_share_bar,
      url: window.location.href,
      title: this.data.pt_og_title,
      description: this.data.pt_og_description,
      hashtags: this.data.pt_twitter_card,
      twitterUser: this.data.pt_twitter_site,
      media: this.data.pt_og_img?.resolutions?.ORIGINAL?.url,
    })
  }
}
