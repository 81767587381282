
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import FooterUpperSection from './FooterUpperSection.vue'
import FooterLowerSection from './FooterLowerSection.vue'
import FooterJubilee from './FooterJubilee.vue'
import { ILink } from '../../shared/general/interfaces/ILink'

@Component({
  name: 'TheFooter',
  components: {
    FooterJubilee,
    FooterUpperSection,
    FooterLowerSection,
  },
})
export default class TheFooter extends Vue {
  @Prop({ required: true }) footerSocialMediaElements! : ILink[]

  @Prop({ required: true }) footerNavLinks! : ILink[]
}
