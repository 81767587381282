
import {
  File, Image, Option, Reference,
} from 'fsxa-api'
import { FSXABaseSection, FSXARichText } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { TCarousel } from '../../../shared/general/types/TCarousel'
import { mapImage } from '../../../shared/general/services/ImageService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { buildPlayerElements, getVideoFormat } from '../../../shared/general/services/VideoUrlService'
import Paragraph from '../richtext/Paragraph.vue'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'
import { notEmpty } from '../../../shared/general/services/TypeAssertions'
import { sendStageAvailable } from '../../../shared/general/services/JubileeService'

interface ISlideData {
  st_headline_formatted ?: FSXARichText
  st_alt_text ?: string
  st_image ?: Image
  st_image_decorative ?: boolean
  st_links ?: {
    data ?: {
      lt_link ?: Image | File | Reference | string
      lt_target ?: boolean
      lt_text ?: string
      lt_title_text ?: string
    }
  }[]
  st_mp4 ?: File
  st_ogv ?: File
  st_webm ?: File
  st_slide_text ?: string
  st_toggle_video ?: boolean
  st_video_format ?: Option
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
}

interface IPayload {
  st_slides ?: TDataEntry<ISlideData>[]
  st_design ?: boolean
}

@Component({
  name: 'StStage',
  components: { Carousel: () => import('../../carousel/Carousel.vue') },
})
export default class StStage extends FSXABaseSection<IPayload> {
  mounted () {
    sendStageAvailable()
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private headlines (data : ISlideData | undefined) : FSXARichText[] | undefined {
    return (data?.st_headline_formatted as any)?.map(
      (text : Paragraph) => text?.content,
    )
  }

  private getLinks (buttons ?: ISlideData) : ILink[] {
    return buttons?.st_links?.map<ILink>((link) => getLinkObject(link, this.getUrlByPageId)) || []
  }

  private get isStageLarge () : boolean {
    return notEmpty(this.payload.st_design) && !this.payload.st_design
  }

  private get carousel () : TCarousel {
    return {
      showDesignLogo: this.isStageLarge,
      slides: normalizeDataEntries(this.payload.st_slides).map((data : ISlideData) => ({
        content: {
          links: this.getLinks(data),
          headlines: this.headlines(data),
          text: data?.st_slide_text || '',
          video: {
            id: data?.st_video_id || '',
            format: getVideoFormat(data?.st_video_format?.key),
            htmlPlayerElements: buildPlayerElements({
              mp4: data?.st_mp4,
              webm: data?.st_webm,
              ogv: data?.st_ogv,
            }),
            privacyHash: data?.st_vimeo_privacy_hash,
          },
        },
        image: mapImage(data?.st_image, data?.st_alt_text, data?.st_alt_text, data?.st_image_decorative),
      })) || [],
      variant: this.isStageLarge ? 'stage-large' : 'stage-medium',
    }
  }
}
