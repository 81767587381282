
import { Component, InjectReactive, Vue } from 'nuxt-property-decorator'
import { ProjectProperties } from 'fsxa-api'
import BaseIcon from './base/BaseIcon.vue'
import { trackJubileeFolding, trackLink } from '../shared/general/tracking/TrackingService'
import { globalLabelAsString } from '../shared/general/services/StoreService'

@Component({
  name: 'TheJubileeFlyIn',
  components: { BaseIcon },
})
export default class TheJubileeFlyIn extends Vue {
  @InjectReactive({ from: 'globalSettings' }) globalSettings! : ProjectProperties | null

  private standardStickFromTop = 240

  private isOpen = false

  private initialFromTop = this.standardStickFromTop

  private useFakeStickyValue = this.standardStickFromTop

  private unfoldTimer : ReturnType<typeof setTimeout> | null = null

  $refs! : {
    container ?: HTMLElement
  }

  mounted () {
    document.addEventListener('stageAvailable', this.stageListener)
    window.addEventListener('scroll', this.fakeStickyOfTriangle)
    this.isOpen = sessionStorage.getItem('jubileeFlyInOpen') === 'true'
    const wasAlreadyUnfoldedAutomatically = sessionStorage.getItem('jubileeFlyInOpenInitUnfolded') === 'true'
    if (!wasAlreadyUnfoldedAutomatically) {
      this.startUnfoldTimer()
    }
  }

  destroyed () {
    document.removeEventListener('stageAvailable', this.stageListener)
    window.removeEventListener('scroll', this.fakeStickyOfTriangle)
    window.removeEventListener('resize', this.checkStageHeight)
  }

  private startUnfoldTimer () : void {
    this.unfoldTimer = setTimeout(() => {
      this.isOpen = true
      sessionStorage.setItem('jubileeFlyInOpenInitUnfolded', 'true')
    }, 8000)
  }

  private stageListener () : void {
    const stage = document.querySelector('.stage')
    this.initialFromTop = (stage?.getBoundingClientRect().bottom ?? 0) + 60 + window.scrollY
    this.useFakeStickyValue = this.initialFromTop
    window.addEventListener('resize', this.checkStageHeight)
  }

  private fakeStickyOfTriangle () : void {
    // We need to fake sticky, because neither fixed nor sticky work with this "super nice" component
    const fakeStickyTop = this.standardStickFromTop
    const container = this.$refs.container!

    if (window.scrollY + fakeStickyTop >= this.initialFromTop) {
      container.style.position = 'fixed'
      this.useFakeStickyValue = fakeStickyTop
    } else {
      container.style.position = 'absolute'
      this.useFakeStickyValue = this.initialFromTop
    }
  }

  private checkStageHeight () : void {
    const stage = document.querySelector('.stage')
    const stageBottom = stage?.getBoundingClientRect().bottom ?? 0
    this.initialFromTop = stageBottom + 60 + window.scrollY
    if (window.scrollY <= stageBottom) {
      this.useFakeStickyValue = this.initialFromTop
    }
  }

  private toggle () : void {
    this.isOpen = !this.isOpen

    if (this.unfoldTimer) {
      clearTimeout(this.unfoldTimer)
    }

    // remember user decision across different pages
    // not the most efficient method, but that thing won't be clicked as much anyway
    sessionStorage.setItem('jubileeFlyInOpen', this.isOpen.toString())
    sessionStorage.setItem('jubileeFlyInOpenInitUnfolded', 'true')

    trackJubileeFolding(
      this.$refs.container as HTMLElement,
      {
        cta_type: this.isOpen ? 'flyin_unfold' : 'flyin_fold',
      },
    )
  }

  private trackExternalLink () : void {
    trackLink(
      this.$refs.container as HTMLElement,
      {
        link_text: 'flyin_link',
        link_url: this.jubileeUrl,
      },
    )
  }

  private get jubileeUrl () : string {
    return this.globalSettings?.data?.ps_jubilee_link
  }

  private get foldButtonLabel () : string {
    return globalLabelAsString(this.isOpen ? 'jubilee_fold_titletext' : 'jubilee_unfold_titletext')
  }
}
