
import { Component, InjectReactive, Vue } from 'nuxt-property-decorator'
import { ProjectProperties } from 'fsxa-api'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import JubileeLogo from '../../static/assets/svg/JubileeFooter.svg'
import BaseButton from '../base/BaseButton.vue'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'FooterJubilee',
  components: {
    BaseButton,
    BaseGridLayout,
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class FooterJubilee extends Vue {
  @InjectReactive({ from: 'globalSettings' }) globalSettings! : ProjectProperties | null

  private logo = JubileeLogo

  private get text () {
    return globalLabel('footer_jubilee_text')
  }

  private get buttonLabel () : string {
    return globalLabelAsString('footer_jubilee_button_text')
  }

  private get jubileeUrl () : string | undefined {
    return this.globalSettings?.data?.ps_jubilee_link
  }
}
